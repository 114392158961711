<template>
  <div class="new-password">
    <h1>Recuperar Contraseña</h1>
    <p>Ingresa el correo que utilizaste al momento de registrarte.</p>
    <div class="input-content full-input label-top">
      <input type="text" value="" name="reset-email" placeholder="Email" />
    </div>
    <input class="submit" type="submit" value="Enviar" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.new-password {
  text-align: center;
  input {
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #d0d0d0;
    height: 70px;
    border-radius: 35px;
    padding: 20px 30px;
    &:focus {
      border-color: #222ac3;
    }
  }
  .submit {
    background: #222ac3;
    color: white;
    font-size: 15px;
    font-family: Poppins;
  }
}
</style>
